<template>
  <div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
    <div class="flex flex-col h-full overflow-y-auto col-span-1 md:col-span-2">
      <div class="flex-1 pt-10 sm:pt-20 px-3 sm:px-0 ">
        <div class="max-w-md px-2 mx-auto">
          <img src="../../../assets/images/dscvry_logo-purple.png" class="h-6"/>
          <Text content="Now let's setup your organization" customClass="mt-8 tracking-tight" weight="medium" size="2xl"
                color="gray-800"/>
        </div>
        <div class="flex flex-col mt-4">
          <div class="max-w-md px-2 mx-auto space-y-6 w-full flex flex-col">
            <div class="space-y-4 rounded-md">
              <Input :isError="inputs.name.error" :help-text="inputs.name.error" id="email"
                     v-model:value="inputs.name.value" :rounded="inputs.name.rounded" :type="inputs.name.type"
                     :customClass="inputs.name.customClass" :label="inputs.name.label"
                     :placeholder="inputs.name.placeholder"/>
              <Input :isError="inputs.website.error" :help-text="inputs.website.error" id="website"
                     v-model:value="inputs.website.value" :rounded="inputs.website.rounded" :type="inputs.website.type"
                     :customClass="inputs.website.customClass" :label="inputs.website.label"
                     :placeholder="inputs.website.placeholder"/>
              <Select :isError="inputs.type.error" :help-text="inputs.type.error"
                      v-model:selected-item="inputs.type.value" :rounded="inputs.type.rounded" :type="inputs.type.type"
                      isFull
                      :customClass="inputs.type.customClass" :label="inputs.type.label" :items="inputs.type.items"
                      :placeholder="inputs.type.placeholder"/>
              <div>
                <Text size="sm" weight="semibold" color="gray-700" content="Logo" custom-class="mb-2"/>
                <FileUploader
                    v-bind="{
											emptyText: 'Select a file',
											emptyIcon: 'CloudUploadIcon',
											editText: 'Edit',
										}"
                    v-model:file="logoFile"
                    @updateLogo="img => logo = img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-none w-full border-t">
        <div class="max-w-md mx-auto  px-5 sm:px-3">
          <div class="flex justify-end py-3 sm:py-6">
            <Button :content="'Next'" variant="primary" :onClick="() => onNext()"
                    :is-loading="isLoading"/>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden h-full col-span-1 md:col-span-3 md:block">
      <img src="../../../assets/images/login_page_sidepanel.png" :alt="image.alt" class="h-screen w-full object-cover"/>
    </div>
  </div>
</template>

<script>
import Text from '../../atoms/Text/Text.vue';
import Link from '../../atoms/Link/Link.vue';
import Checkbox from '../../molecules/Inputs/Checkbox/Checkbox.vue';
import Input from '../../molecules/Inputs/Component/Component.vue';
import Select from '../../molecules/Selects/Component/Component.vue';
import Button from '../../atoms/Button/Button.vue';
import Icon from '../../atoms/Icons/Icons.vue';
import PasswordInput from '../../molecules/Inputs/PasswordInput/PasswordInput.vue'
import FileUploader
  from '../../molecules/FilePickers/SimpleWithEmptyStateAndImagePreview/SimpleWithEmptyStateAndImagePreview'
import {getAWSCredential} from "../../../utils/utils";
import AWS from "aws-sdk";

export default {
  components: {
    Text,
    Link,
    Checkbox,
    Input,
    Button,
    Icon,
    PasswordInput,
    Select,
    FileUploader
  },
  props: {
    image: {
      type: Object,
      default: () => {
      }
    },
    logo: {
      type: Object,
      default: () => {
      }
    },
    inputs: {
      type: Object,
      default: () => {
      }
    },
    button: {
      type: Object,
      default: () => {
      }
    },
    footerTitle: {
      type: String,
      default: ''
    },
    icons: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      logo: null,
      isLoading: false,
      user: null,
      logoFile: null,
    }
  },
  methods: {
    onUploadAvatar: async function (file) {
      let filename = file.name.split(' ').join('-');
      const paths = filename.split('.');
      if (paths.length > 0) paths[paths.length - 2] += `.${new Date().getTime()}`
      filename = paths?.join('.')
      const {s3} = await getAWSCredential(this.actions)
      const s3Bucket = new AWS.S3({
        params: {Bucket: s3.bucket},
        apiVersion: '2006-03-01',
        region: s3.region,
        credentials: {
          accessKeyId: s3.Credentials.AccessKeyId,
          secretAccessKey: s3.Credentials.SecretAccessKey,
          sessionToken: s3.Credentials.SessionToken
        }
      })

      const uploadImageToS3Promise = new Promise((resolve, reject) => {
        let contentType = file.type;
        let contentDeposition = 'inline;filename="' + filename + '"';
        const params = {
          Bucket: s3.bucket,
          Key: filename,
          Body: file,
          ContentDisposition: contentDeposition,
          ContentType: contentType,
          ACL: 'public-read'
        };
        s3Bucket.upload(params, (err, res) => {
          if (err) {
            console.log('error in callback');
            reject()
            return false;
          }
          console.log("Response URL : " + res.Location);
          this.logo = res.Location;
          resolve();
        });
      })
      await uploadImageToS3Promise.then();
    },
    onNext: async function () {
      if (!this.inputs.name.value) {
        this.inputs.name.error = 'Please input name!';
        return false;
      }

      if (!this.inputs.website.value) {
        this.inputs.website.error = 'Please enter your website above!';
        return false;
      }

      const regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/i;
      if (!regex.test(this.inputs.website.value)) {
        this.inputs.website.error = 'Please enter valid website!';
        return false;
      }
      let avatar = null
      if (this.logo) {
        const isLogo = this.logo && (this.logo.split(':')[0] === 'http' || this.logo.split(':')[0] === 'https');
        if (!isLogo && this.logoFile) {
          avatar = await this.onUploadAvatar(this.logoFile);
        } else {
          avatar = this.logo
        }
      }
      console.log(avatar, 'avatar')
      this.isLoading = true;
      const slug = `/company/${this.inputs.name.value.toLowerCase().split(' ').join('-')}`
      try {
        const params = {
          where: {id: this.state.currentUser?.id},
          data: {company: {}},
        };
        if (this.state.currentUser?.company?.id) {
          params.data.company = {
            update: {
              name: this.inputs.name.value,
              username: this.inputs.name.value,
              type: this.inputs.type.value?.name,
              url: this.inputs.website.value?.toLowerCase(),
              owner: {connect: {id: this.state.currentUser?.id}},
              // description: this.state.user.companyDetail?.description || null,
              slug,
              avatar,
              // employees: {
              //   update: [{
              //     where: {id: state.currentUser?.company?.employees[0]?.id},
              //     data: {
              //       user: {connect: {id: state.currentUser?.id}}
              //     },
              //   }],
              // },
            },
          };
        } else {
          const companyParams = {
            addProducts: ['Take One - Free Trial'],
            data: {
              name: this.inputs.name.value,
              username: this.inputs.name.value,
              type: this.inputs.type.value?.name,
              url: this.inputs.website.value,
              owner: {connect: {id: this.state.currentUser?.id}},
              // description: this.state.user.companyDetail?.description,
              avatar,
              slug,
              addresses: {},
              // metadata: {
              //   twitter: this.state.user.companyDetail?.twitter?.handle || '',
              //   linkedin: this.state.user.companyDetail?.linkedin?.handle || '',
              //   facebook: this.state.user?.companyDetail?.facebook?.handle || '',
              //   crunchbase: this.state.user.companyDetail?.crunchbase?.handle || '',
              // },
              employees: {
                create: [{
                  user: {connect: {id: this.state.currentUser?.id}},
                }],
              },
              users: {
                connect: [{id: this.state.currentUser?.id}]
              }
            }
          }

          // if (this.$route.query?.tag) {
          //   const tags = await this.actions.tag.getTags({where: {slug: this.$route.query?.tag}, getValues: true});
          //   if (tags?.length > 0) {
          //     companyParams.data.tags = {
          //       connect: [{
          //         id: tags[0]?.id
          //       }]
          //     }
          //   }
          // }

          // if (this.state.user?.companyDetail?.geo) {
          //   const address = this.state.user?.companyDetail?.geo;
          //   companyParams.data.addresses = {
          //     create: [{
          //       address: address?.streetAddress || '',
          //       city: address?.city || '',
          //       state: address?.state || '',
          //       postalCode: address?.postalCode|| '',
          //       country: address?.country || '',
          //       gps: {
          //         create: {
          //           lat: address?.lat || 0,
          //           lon: address?.lng || 0,
          //         }
          //       }
          //     }]
          //   }
          // }
          console.log(companyParams, 'companyParams');
          const saveCompany = await this.actions.company.saveCompany(companyParams)
          params.data.company = {
            connect: {
              id: saveCompany?.id
            },
          };
          const group = this.state.currentUser?.groups?.find(g => g?.name === 'ecosystem-owner');
          if (group?.id) {
          	params.data.groups = {
          		update: [{
          			where: {id: group?.id},
								data: {
          				company: {connect: {id: saveCompany?.id}}
								}
							}]
						}
					}
        }
        console.log(params, 'params');
        const user = await this.actions.user.saveUser(params);
        console.log(user)
        if (user?.id && user?.company?.id && !user?.groups?.find(g => g.type === 'DEFAULT_COMPANY')) {
          const {saveGroup} = await this.actions.group.saveGroup({
            data: {
              type: 'DEFAULT_COMPANY',
              name: 'All ' + user?.company?.name,
              company: {connect: {id: user?.company?.id}},
            },
          });

          const group = await this.actions.group.saveGroup({
            data: {
              type: 'ROLE',
              name: 'admin',
              company: {connect: {id: user?.company?.id}},
            },
          });

          const group1 = await this.actions.group.saveGroup({
            data: {
              type: 'ROLE',
              name: 'employee',
              company: {connect: {id: user?.company?.id}},
            },
          });

          console.log(saveGroup, group.saveGroup, 'saveGroup');

          if (saveGroup?.id) {
            await this.actions.user.saveUser({
              where: {id: user.id},
              data: {
                groups: {connect: [{id: saveGroup.id}, {id: group?.saveGroup?.id}, {id: group1.saveGroup?.id}]},
                company: {
                  update: {
                    groups: {connect: [{id: saveGroup.id}]},
                  },
                },
                userGroups: {
                  create: [{
                    group: {connect: {id: saveGroup?.id}},
                    subgroups: {
                      create: [
                        {
                          group: {
                            create: {
                              name: 'director',
                              type: 'ROLE',
                              company: {connect: {id: user?.company?.id}},
                            },
                          },
                        }
                      ],
                    },
                  }],
                }
              },
            });
          }
        }
        console.log(user, 'user ==============');
        if (user?.id) {
          // try {
          //   await this.actions.user.getClearbitPerson({email: this.state.currentUser?.email});
          // } catch (e) {
          //   console.log(e)
          // }
          this.$router.push('/setup-payment')
        }
      } catch (e) {
        console.log(e, 'saveUser error')
      } finally {
        this.isLoading = false;
      }
      console.log("next clicked");
    },
  },
  async mounted() {
    this.inputs.website.value = this.state.currentUser?.email?.split('@')[1]
    // await this.actions.loginWithToken({userId: this.$route.query?.u, token: this.$route.query?.t})
  }
};
</script>
