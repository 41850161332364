<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
	/>
</template>
<script>
import Template from '../components/templates/RegisterCompany/RegisterCompany';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	methods: {
	},
	data() {
		return {
			params: c.register_company,
			isLoading: false,
      email: null,
		}
	},
  async mounted() {
  }
}
</script>
